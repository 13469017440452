import { isMobile } from '../utils/index'
export default {
  address: '新北市板橋區華江八路70號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.9617872641097!2d121.46601131557011!3d25.03537084438926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd9919921fe48a447!2zMjXCsDAyJzA3LjMiTiAxMjHCsDI4JzA1LjUiRQ!5e0!3m2!1szh-TW!2stw!4v1666950888153!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/BpgGt2r5rxCqbtQx9',
  phone: '02-2252-7755',
  fbLink: 'https://www.facebook.com/100085391143657',
  fbMessage: 'https://m.me/100085391143657',
  caseName: '豐光',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '潤旺建設股份有限公司',],
    ['建築設計', '李承洋建築師',],
    ['行銷企劃', '澄果廣告有限公司',],
    ['經紀人', '翁文正（95）北市經證字第00592號',],
  ],

  gtmCode: ['P6WKXZF'], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    // subTitle: '若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！
  //  room_type: ['兩房','三房'],//房型
  },
  lixin_logo:true, //false

}