<template>
  <div> 
    <div id="contact-info" class="contact-info" data-aos="fade" data-aos-delay="0">
      <div class="imglb" data-aos="zoom-in" data-aos-delay="0" data-aos-offset="-300">
      <div>
    <img src="@/projects/fong-guang/s1/w.png" class="img1" />
    <img src="@/projects/fong-guang/s1/w.gif" class="img2" /></div>
    </div>
    <div class="imgrb" data-aos="zoom-in" data-aos-delay="0" data-aos-offset="-300">
      <div>
    <img src="@/projects/fong-guang/s1/w.png" class="img1" />
    <img src="@/projects/fong-guang/s1/w.gif" class="img2" /></div>
    </div>
    <div class="tree" data-aos="fade-up" data-aos-delay="0" data-aos-offset="-300">
    <img src="@/projects/fong-guang/s3/bg.jpg" class="bg1" />
    <img src="@/projects/fong-guang/s1/tree.png" class="tree1" />
    <img src="@/projects/fong-guang/s1/tree.png" class="tree2" />
    <img src="@/projects/fong-guang/s1/img.png" class="tree4" />
    </div>

 <svg class="logo" data-aos="zoom-in" data-aos-delay="200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 224">
  <path d="M34.9,205.9c-0.8-0.7-1.7-1.4-2.7-2.1l1-1.2c0.4,0.3,1.2,0.9,2.1,1.6c0.3,0.2,0.5,0.4,0.7,0.5L34.9,205.9
	L34.9,205.9z M34.3,213.2l-1.6-0.7c0.8-2.3,1.5-4.1,1.8-5.4c0.4,0.2,1,0.5,1.6,0.6C35.4,209.9,34.8,211.7,34.3,213.2z M35.4,201.8
	c-0.9-0.8-1.7-1.5-2.5-2.1l1.1-1.2c0.9,0.7,1.8,1.4,2.6,2.1L35.4,201.8L35.4,201.8z M37.3,201.3v-1.6h9.1v1.6h-3.8v9.5H47v1.5H36.5
	v-1.5h4.4v-9.5H37.3L37.3,201.3z M55.7,211.3V210h6.4v-0.8h1.6v0.8h6.4v1.2h-6.4v2.1h-1.6v-2.1H55.7z M56.3,203l-0.4-1.1
	c1.5-0.3,2.9-0.9,4.3-1.7h-4V199h6.2v5h-1.5v-0.8c-1.3,0.8-2.7,1.4-4.2,1.9l-0.4-1.2c1.7-0.4,3.2-1.1,4.6-1.9V201
	C59.4,201.9,57.8,202.6,56.3,203L56.3,203z M63.4,202.7l-0.5-1c1.6-0.4,2.9-0.9,4.1-1.5h-3.9V199h6.3v5.2h-1.5v-1.4
	c-1.5,0.8-2.8,1.3-4.1,1.6v0.6h5.8v1.2h-2.4c-0.1,0.2-0.3,0.4-0.4,0.7c1,0.5,1.9,1,2.7,1.5l-0.9,1.1c-1.1-0.8-1.9-1.3-2.5-1.7
	c-0.4,0.5-1,1-1.8,1.5l-1.1-1.1c1-0.5,1.8-1.2,2.4-2.1h-4.7c-0.1,0.1-0.4,0.6-0.5,0.8c0.5,0.2,1.8,1,2.2,1.3l-1,1.1
	c-0.6-0.4-1.3-0.9-2.1-1.4c-0.5,0.5-1.2,1.1-2,1.6c-0.1-0.1-0.9-0.7-1.2-1c1.5-0.8,2.4-1.6,2.8-2.4h-2.8V205h5.8v-1h1.3l-0.2-0.6
	c1.7-0.5,3.3-1.1,4.6-1.8v-0.8C66.4,201.7,64.9,202.3,63.4,202.7L63.4,202.7z M79.4,201.2v-1.4h2.7v-1.5h1.5v1.5h2.8v1.4h-5.2
	c0.2,0.3,0.9,1.4,1.2,1.8l-0.9,0.5h1.8c0.4-0.8,0.8-1.5,1.2-2.3l1.4,0.5c-0.4,0.6-0.7,1.2-1.1,1.8h1.9v1.4h-3v1.4h2.7v1.4h-2.7v0.8
	l0.6-0.5c0.5,0.4,1.2,1.2,2.2,2.3l-1,0.9c-0.5-0.6-1.6-2-1.8-2.2v3.9h-1.5v-4c-0.7,1.2-1.5,2.2-2.5,3.1l-0.9-1.1
	c1.2-1,2.2-2,2.8-3.3h-2.2v-1.4h2.8v-1.4h-3.2v-1.4H81c-0.4-0.6-0.7-1.2-1.1-1.7l1.1-0.6L79.4,201.2L79.4,201.2z M90.5,213.1v-7.8
	h-1.8c0.1,3.3-0.5,6.1-1.7,7.9c-0.2-0.1-1.1-0.7-1.3-0.9c1.1-1.5,1.6-3.7,1.5-6.8v-6c2.2-0.2,4.6-0.4,5.7-0.6l0.5,1.5
	c-1.5,0.2-3.6,0.4-4.7,0.5v2.9h4.9v1.5H92v7.8L90.5,213.1L90.5,213.1z M102.7,202.9v-3.3h6.1v-1.2h1.7v1.2h5.9v3.3h-1.6v-2h-10.6v2
	H102.7L102.7,202.9z M103.5,213.3v-6.1h12.2v6.1h-1.6v-0.7h-9v0.7H103.5z M104.7,206.4v-2.9h9.8v2.9H104.7z M105,202.7v-0.9h9.1v0.9
	H105z M105,208.4v1.1h3.8v-1.1H105z M105,210.5v1h3.8v-1H105z M106.3,204.5v1h6.6v-1H106.3z M114.1,208.4h-3.7v1.1h3.7V208.4z
	 M110.4,210.5v1h3.7v-1H110.4z M126.4,200.3v-1.4h13.5v1.4h-11.6V211c0,0.3,0.2,0.5,0.6,0.5h11.2v1.3h-11.9c-0.9,0-1.4-0.5-1.4-1.3
	v-11.2H126.4L126.4,200.3z M129.3,210.4v-4.2h4.5v4.2H129.3z M130.6,205.2v-3.9h7.6v3.9H130.6z M130.6,207.3v1.9h1.9v-1.9H130.6z
	 M132,202.5v1.6h4.7v-1.6H132z M134.9,210.4v-4.2h4.5v4.2H134.9z M136.1,207.3v1.9h2.1v-1.9H136.1z M152.2,205.5v2.3H150v-2.3H152.2
	z M162.9,213.3c-0.4-0.5-0.8-0.9-1.1-1.3c1.4-2.6,1.2-3.9,1.3-7.5v-5.5h11.5c0,0,0,5.1,0,6.8c0,3.8,0.1,5.4,0.6,5.5
	c0.3,0.1,0.5-0.5,0.5-3.1c0.4,0.2,0.7,0.4,1,0.4c-0.1,4-0.7,4.5-1.8,4.5c-1.1,0-1.7-1.2-1.7-7.3v-5.5h-8.7v4.7
	C164.4,208.9,164.4,210.4,162.9,213.3L162.9,213.3z M165.5,208.7v-4.3h2.7v-1.4c-0.5,0-2.3,0.1-2.8,0.1c0-0.5-0.1-0.9-0.2-1.3
	c2.8,0,5.1-0.1,7-0.2l0.2,1.2c-0.9,0-1.8,0.1-2.9,0.2v1.4h2.7v4.3h-2.7v1.8l1.5-0.1l-0.4-1.1l1.1-0.2c0.2,0.6,0.9,2.6,1.2,3.3
	l-1.2,0.3l-0.3-1.2c-1.5,0.2-5.5,0.5-6.4,0.5l-0.2-1.3c0.7,0,2.2,0,3.4-0.1v-1.9L165.5,208.7L165.5,208.7z M166.7,205.5v1.9h1.5
	v-1.9H166.7z M170.9,205.5h-1.6v1.9h1.6V205.5z M189.2,206.1h-3.9h0v-1.6h6.4v-6.2h1.7v6.2h6.4v1.6h-4.7v4.4c0,0.7,0.3,1,1,1h1
	c1.4-0.2,1-1.8,1.2-3.1c0.6,0.3,1.1,0.5,1.6,0.6c-0.4,2.5,0,3.9-2.6,3.9h-1.7c-1.4,0-2.1-0.7-2.1-2.1v-4.8h-2.6
	c0.2,3.3-1.3,5.9-4.7,7.2c-0.2-0.2-0.8-1.1-1.1-1.5C188.3,210.5,189.2,209.1,189.2,206.1z M188.9,203.6c-1.2-1.5-2.1-2.6-2.7-3.3
	l1.2-1c0.9,1,1.8,2,2.8,3.1L188.9,203.6L188.9,203.6z M196,203.7l-1.1-1.1c1-1.1,1.9-2.1,2.8-3.3l1.3,1
	C198.3,201.1,197.4,202.2,196,203.7z M208.6,200.4v-1.3h6.8v1.3h-1.7v1.9h1.5v10.9H214v-0.9h-3.8v0.9H209v-10.9h1.6v-1.9H208.6
	L208.6,200.4z M210.1,203.5v3.3c0.5-0.8,0.8-1.9,0.7-3.3H210.1z M213.5,207.2c-0.6,0-0.9-0.4-1-1.2v-2.5h-0.8c0,1.6-0.3,3-0.9,4.2
	c-0.2-0.1-0.4-0.3-0.6-0.5v1.2h3.8v-1.2H213.5z M210.2,209.6v1.6h3.8v-1.6H210.2z M211.7,200.4v1.9h0.8v-1.9H211.7z M214.1,203.6
	h-0.7v1.9c0,0.5,0.1,0.7,0.6,0.6h0.2V203.6L214.1,203.6z M215.7,210.4v-1.3h3.2v-0.3l1.7-1.1h-4.2v-1.2h6.2v1.3l-2.1,1.4h2.9v1.3h-3
	v1.1c0,1.1-0.4,1.6-1.5,1.6H217c0-0.4-0.1-0.9-0.2-1.3h1.3c0.5,0,0.8-0.2,0.8-0.6v-0.8L215.7,210.4L215.7,210.4z M215.9,201v-1.3
	h2.8v-1.3h1.5v1.3h2.9v1.3H215.9z M216.4,205.5v-3.6h5.9v3.6H216.4z M217.8,203.1v1.2h3.3v-1.2H217.8z M234.2,205.8
	c-0.8-0.7-1.6-1.4-2.4-2l1-1.1c0.8,0.6,1.6,1.2,2.4,1.9L234.2,205.8z M233.5,213.2l-1.5-0.5c0-0.2,0.1-0.4,0.2-0.8
	c0.6-2.1,1.1-3.7,1.4-5c0.5,0.2,1,0.4,1.5,0.6c-0.2,0.8-0.6,2.1-1.1,3.9C233.8,212.2,233.6,212.8,233.5,213.2L233.5,213.2z
	 M234.5,201.7c-0.6-0.6-1.4-1.3-2.3-2.1l1-1.1c0.8,0.6,1.7,1.3,2.4,2L234.5,201.7z M236.2,209.9v-1.2h3.6v-1.4h-4.3V206h4.3v-1.3
	h-3.5v-1.2h6.6l-0.4-0.2c0.5-0.8,0.9-1.7,1.4-2.6l1.5,0.4c-0.6,1-1,1.8-1.4,2.4h1.5v2.6h1v1.3h-1v3.4H244V210h-2.6v1.3
	c0,1.2-0.6,1.9-1.9,1.9c-0.6,0-1.6,0.1-2,0.1c0-0.6-0.1-1.1-0.3-1.4c0.3,0,0.9,0.1,1.7,0.1c0.6,0,1-0.3,1-0.8v-1.1H236.2
	L236.2,209.9z M236,200.5c0-0.2-0.1-0.4-0.1-0.7c-0.1-0.3-0.1-0.5-0.2-0.7c3.5-0.1,6.9-0.2,10-0.4l0.2,1.4
	C242.5,200.4,239.2,200.5,236,200.5z M237.1,203.2c-0.4-0.8-0.7-1.4-0.9-1.8l1.4-0.4c0.2,0.4,0.8,1.4,1,1.8L237.1,203.2L237.1,203.2
	z M240.3,202.9c-0.2-0.6-0.5-1.3-0.8-1.9l1.5-0.3c0.3,0.6,0.6,1.2,0.8,1.9L240.3,202.9z M244,204.7h-2.6v1.3h2.6V204.7z
	 M241.4,207.3v1.4h2.6v-1.4H241.4z M260.8,211.3v1.2h-4.2l0,0v-1.2H260.8z M259.5,205.7h-2.2v3.3l1.7-0.9c0,0.3,0.2,1.1,0.3,1.4
	c-1.2,0.7-2.4,1.3-3.5,2l-0.7-1.3l0.7-0.4v-10.2c2.4-0.1,4.7-0.3,6.9-0.6l0.4,1.5c-0.5,0.1-1.4,0.2-2.3,0.2c0,1.5,0,2.6,0.1,3.5h2.3
	v1.4h-2.2c0.1,1.7,0.3,4.5,1.1,4.5c0.4,0,0.3-1.5,0.5-2.6c0.4,0.2,0.8,0.4,1,0.4c-0.2,1.5,0,3.7-1.6,3.7
	C260.9,211.8,259.8,211.2,259.5,205.7z M259.4,204.2c0-0.8-0.1-1.9-0.1-3.3c-0.4,0-1.6,0.1-2.1,0.1v3.2L259.4,204.2L259.4,204.2z
	 M264,213.3v-14.1h5.5v1.5l-1.4,4c2.1,1.6,2.6,6.1,0,6.4c-0.3,0-1.2,0.1-1.9,0.1c0-0.4-0.2-1.1-0.3-1.6h1.2c2,0,1.1-3.2-0.6-4.7
	l1.3-4.3h-2.4v12.6H264L264,213.3z"/>
<path d="M133.3,42.9c1.4,8.4-5.3,9.9-17,24.1c-8.6,9.6-15.6,15.9-21.2,18.9c0.5,3.7-0.3,9.5-5,9.3
	c-7.6-1.1-37.2,11.3-42.1,16.7c10.7-2.4,41.9-13,54.8-3.6c3.7,2.7,1,8.5-1.1,8.2c-1.8-0.3-17.7,6.1-16.9,7.8c0.6,1.3,2.9,0.8,3.3,2
	c0.4,1.9-1.5,6.3-4,6.1c-4.4,0.1-21.5,5.2-25.7,8.1c0.4,2.2,1.2,3.1-2.8,2.8c-10.6-0.6-17.2-21.7-22.2-27.2
	c-2.8-8.2,6.4-5.1,10.9-4.6c4.1-3.2,17.6-11.8,19.7-15.1c-5.6-2.4-27,12.1-25-1.8c-0.2,0.4,33.5-14.3,33.8-12.9
	c18,0.8,15.1,2.5,26.2-12.6c-8.3-1.2-20-0.2-30,2.3c-0.2,3.6-4,5.9-5.6,1.8c-55.2,20.8-25.4,35-60-20.2C0,43,15.2,49.4,16.7,53.1
	c12.6,42.3,6.6,21.4,45.4,11.6c-1.7-16.2-2.3-35.5-1.3-52.2c0.7-4.2-8.4-4.2-5.8-8.2c3.8-4.3,19.9,2.9,18.9,8.2
	c-2.2,1.8-4,46.6-4.3,50.2c5.2-1.5,10-2.2,15.6-2.5c-0.8-2.7-1-7-0.5-9.6c-5.3-0.1-9.7,2.9-10.8-0.5c0.1-3.8,0.5-5.1,4.3-6.6
	c4.1-2,6.9-1.5,7.6-5.8C82,37.8,74,41.5,75.2,34.8c1.7-2.1,8.1-3.2,10.9-3.9v-4c-16.3,3.5-13.1-4.4,0-7.6c-0.1-3.3-0.2-7.2-1.5-9.3
	c-1.1-1.1-0.4-2.6,0.6-3.7c2.9-3.4,10.2,2.9,11.2,4c1.6,2-0.1,5.4-1.3,7.4c0.5,0.1,4.1-0.7,4.5,0c1.6,1.2,4.8,4,3.8,6.3
	c-2.2,2-5.4,0.7-8.8,1.5c0,0.5-0.9,2.9-0.8,3.5c0.8,0,5.2,0.8,6.1,1.3c2.2,6.3-7.7,3.9-7.1,10.1c3.8-2.7,9.6-2.2,11.1,2.5
	c-1.5,2.5-7.7,4.7-11.4,6.1c-0.2,3.8-0.9,7.4-2,11.1c4.2,0.4,8.6,0.7,12.9,2.5c4.8-10,15.5-21.7,9.6-29c-5.2-7.6,9.1-0.5,11.1,0.5
	C129.9,34.7,132.5,38.1,133.3,42.9z M120.6,165.6c3,2,0.1,8.8-2.7,10c-2.1,0.9-3.8-0.6-11-3.2c-20.8-7.7-54.8-3.7-76.6,7.3
	c-6.8,3.6-17-13.3-12.7-13.1c1.6,0,2.4,2.3,7.6,2.1c9.4-0.3,22.5-5.3,32.4-6.2c16.9-11.6,24.3-17.3,22.8-19
	c-2.9-3.2-15.6,4.1-24.3,14.5c-0.7,0.8-0.9,2.5-1.1,3.3c-3,1.4-9.4-3-10.7-5.8c-0.9-2.3-5.5-12.1-0.1-12.6c1.2-0.1,4.2,8.6,11.1,5.5
	c26.1-11.7,23.5-12.6,31.8-10c11.3,3.5,8.2,2.6,8.4,5.9c0.3,4.7-1.8,3-26,16.3C86.7,158.5,103.5,159.8,120.6,165.6L120.6,165.6z
	 M59.6,45.3c-3.9,2.8-8.6,2.5-13.1,3.8c0.2,4.8,0.8,12.9,1.5,16.4c0.5,3-1.9,4-4.3,4c-4.7-2.7-3.8-10.8-4.8-15.9
	c-23.8,12.3-14.5-1.4-1.2-9.1V42c-3,0.8-6.2,2.2-9.3,3.5c-1.7,0.7-4.1-3-2.8-4.5c1.8-2.2,8-4.6,11.4-5.6l-0.5-4.5
	c-8.1,2.5-9.5,2.6-11.3,0.3c-1.4-1.7,5-5.4,10.5-7.7c-0.3-2.1-2-6.5-2.3-8.1c-6.3-7.3,5.1-6.4,9.2-3.7c4.4,2,1.8,5.1,1.6,8.2
	c2.8-0.3,5.1,1.5,7.1,3.7c3.4,4-4.4,3.7-6.6,5.2v3.5c2.2-0.7,7.7-2.3,8.7-0.9c1.7,2.5,0.5,4.7-2,5.7c-1.8,0.7-2.9,1.5-5.4,3.2
	c0,0.6,0,1.3,0,1.8c0,0,9.3-4.3,10.1-4.3C58.2,38.7,61.1,43.1,59.6,45.3z M85,111.6c-7.5-3.5-25.5,3.4-35.3,8.1
	c0.9,2.9,4.7,10.2,5.8,13.1C61.1,130.9,83.1,116.1,85,111.6L85,111.6z M292.1,143.2c8.6,6.3,9.5,15.7-0.8,21.9
	c-3.7,0.5-13.8,0.9-30.4,1.1c-25.2-1.1-28.9,4.3-41.1-7.6c-8.2-11.7-6-8.6-1.5-26.6c2.3-10.4,6-21.5,2.2-28.8
	c-21,22.1-37.5,61.5-71.1,63.3l-0.5-3.3c26.6-15.4,45.1-51.3,65.4-73c-15.1,3.3-37.2,23.4-51.3,8.9c-1.8-1.5-5.2-5.8-1.7-6.8
	c6.7,1.3,13,4.1,20.6,0c3.6-1.9,25-11.5,29.5-13.6c-4.2-7.7-2.5-20.4-3.3-29c-8,4.3-10.4,22.1-17.9,19.9
	c-4.7-1.6-14.9-11.4-9.1-16.7c2.4,0,4.3,4.4,7.2,4.3c6.5-0.9,7.7-8.9,19.5-14.9c-1.3-9.1,0.1-21.2-2.8-23c-2.5-1.5-5.5-7.6-2.1-9.1
	c4.8-2.1,32.2,11.5,28.1,18.4c-5.7,7.4-7.5,24.6-9.8,35.3c5.7-5.4,15.3-13.1,19.1-20.4c2.2-3-1-8.2,3.7-8.2
	c4.1,0.4,15.5,4.7,14.6,9.7c-2.7,3-33.1,22.4-37.3,23.7c-0.8,4-1.6,6.6-2.3,7.8c1.2-0.4,12.3-5,13.6-5.8c2-2,2.6-3.8,5.2-4
	c9.6-0.2,30,10.4,8.9,14.7c-5.6,0.9-10.7,1.1-20.9,15.8c4.5,2.7,17.5,11.8,14.1,17.7c-5.3,7.1-16.3,21.9-13.1,31
	c3.1,6.3,41.6,9.2,45.4,3.5c2.8-8.2,0.6-25.1-8.3-31.5l3.3-1.3C272.7,119,288.7,139.9,292.1,143.2L292.1,143.2z"/>
</svg>
      <div class="info">
        <div class="btn flex-c" @click="showCallDialog" data-aos="fade-down" data-aos-delay="100">
          <span class="flex-c">
            <font-awesome-icon icon="phone" />
            <span>{{info.phone}}</span>
          </span>
        </div>
        <div class="btn flex-c" @click="showMessengerDialog" data-aos="fade-down" data-aos-delay="200">
          <span class="flex-c">
            <font-awesome-icon :icon="['fab', 'facebook-messenger']" /><span>FB 諮詢</span>
          </span>
        </div>
        <a class="btn flex-c btfanpage" :href="info.fbLink" target="_blank" data-aos="fade-down" data-aos-delay="300">
          <span class="flex-c">
            <font-awesome-icon :icon="['fab', 'facebook-f']" /><span>前往粉絲專頁</span>
          </span>
        </a>
        <div class="addressall" data-aos="fade-down" data-aos-delay="400">
          <div class="address flex-c">接待中心：<br v-if="isMobile">{{info.address}}</div>
          <div class="google-btn flex-c" @click="showMapDialog">
            <span class="flex-c">
              <font-awesome-icon icon="map-marker-alt" /><span>導航 Google 地圖</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title :visible.sync="isShowCallDialog" :width="isMobile ? '90%' : '500px'" :modal-append-to-body="false">
      <CallDialog :phone="info.phone" />
    </el-dialog>
    <el-dialog title :visible.sync="isShowMessengerDialog" :width="isMobile ? '90%' : '500px'" :modal-append-to-body="false">
      <MessengerDialog :messenger="info.fbMessage" />
    </el-dialog>
    <el-dialog title :visible.sync="isShowMapDialog" :width="isMobile ? '90%' : '500px'" :modal-append-to-body="false">
      <MapDialog :link="info.googleLink" :address="info.address" />
    </el-dialog>
  </div>
</template>

<script>
import info from '@/info'
import { isMobile, isTablet } from '@/utils'
import CallDialog from '@/components/Dialog/Call'
import MessengerDialog from '@/components/Dialog/Messenger'
import MapDialog from '@/components/Dialog/Map'
export default {
  name: 'contactInfo',
  components: {
    CallDialog,
    MessengerDialog,
    MapDialog,
  },
  data() {
    return {
      info,

      isMobile,
      isTablet,
      isShowCallDialog: false,
      isShowMessengerDialog: false,
      isShowMapDialog: false,
    }
  },
  methods: {
    showCallDialog() {
      // if (!this.isMobile) return
      this.isShowCallDialog = true
    },
    showMessengerDialog() {
      // if (!this.isMobile) return
      this.isShowMessengerDialog = true
    },

    showMapDialog() {
      // if (!this.isMobile) return
      this.isShowMapDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variableColor.scss';
@import '@/assets/style/variableDefault.scss';

.contact-info {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  transform: translateY(0);
  margin: 5vw auto 2vw;
  padding: 3vw 0;
  line-height: 1.4;
  &::before{content: "";
  position: absolute;top: 0;left: 0;right: 0;bottom: 0;
  background: $contact_bg;
 display: block;z-index: 2;}
}

.img1{position:absolute; width: 100%;}
.img2{position:absolute; width: 100%;
 filter: blur(size(1));
}
.imglb{position:absolute;top:size(280);left:size(-800);transform-origin: 0% 0%; width: size(1450);z-index: 2;

> div{
   transform: rotate(13deg);}
img{left: 0;bottom: 0;}

}
.imgrb{position:absolute;top:size(270);right: size(-750);transform-origin: 100% 0%;width: size(1400);z-index: 2;
> div{transform: rotate(-21deg);}
img{right: 0;bottom: 0;}

}
.tree{position:absolute;top:size(0);left: 0;width: 100%;
  .bg1{position:absolute;top:size(-150);right:0;width:90%;transform: rotate(180deg);}
.tree1{position: absolute;right:size(410);top:size(140);width:size(135);}
.tree2{position: absolute;left:size(280);top:size(0);width:size(210);}
.tree4{position: absolute;left:size(-10);top:size(0);width:size(200);}
}

.logo {
  width: $contact_logo_pc_width;
  height: auto;
  // height:;
  margin: 1vw auto 3vw;
  padding: 0 0 0 0;
  fill: #FFF;
  position: relative;z-index: 3;
      filter: drop-shadow(0.3vw 0.2vw 0.1vw #000c);
}
.info {
  width: 880px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;z-index: 3;
}

.btn {
  width: 280px;
  height: 60px;
  font-size: 16px;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
  color: $contact_btn_color;
  background: $contact_btn_bg;
 // box-shadow: $contact_btn_border;
  border: $contact_btn_border;
  border-radius: .5em;
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
  font-weight: 600;
  &.half {
    width: 49%;
  }
  svg {
    color: $contact_btn_icon;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    transition: all 0.5s;
  }

  &:hover {
    background: $contact_btn_hover_bg;
    // background-image: url("../assets/img/contact_gold_button.png");
    color: $contact_btn_hover_color;
    box-shadow: none !important;

    svg {
      color: $contact_btn_hover_icon;
    }
  }
  &::before {
    content: '';
    width: 40%;
    height: 100%;
    display: block;
    background: #fff;
    position: absolute;
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    left: -10%;
    opacity: 0;
    top: 0;
    z-index: 5;
    transition: all 0.4s cubic-bezier(0.2, 0.95, 0.57, 0.99);
  }
  &:hover:before {
    opacity: 1;
    width: 90%;
    left: 140%;
  }
}
.addressall{
  width: 99%;
  border-radius: .5em;
  background: #EEE;
}
.address {
  width:calc(100% - 280px);
  height: 60px;
  color: #040000;
  font-weight: bold;
  float: left;
  //box-shadow: $contact_btn_border;
  //border: $contact_btn_border;
}
.google-btn {
  width: 280px;
  height: 60px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  left: 1%;
  text-decoration: none;
  // border-top-right-radius: 60px;
  //border-bottom-right-radius: 60px;
  color: $contact_google_btn_color;
  background: $contact_google_btn_bg;
  background-position: center !important;
  border: $contact_btn_border;
  transition: all 0.5s;
  border-radius: .5em;
  font-weight: 600;

  svg {
    color: $contact_google_btn_icon;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    transition: all 0.5s;
  }

  &:hover {
    background: $contact_google_hover_btn_bg;
    color: $contact_google_hover_btn_color;

    svg {
      color: $contact_google_hover_btn_icon;
    }
  }
}

/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .contact-info {
    display: flex;
    width: 90% !important;

    .logo {
      width: $contact_logo_tablet_width;
    }
  }
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .contact-info {
  //  background: $contact_bg_m;
    background-size: 250% auto;
    display: flex;
    width: 100%;
    //  max-width: 95%;
    height: auto;
    padding:0 5% 5% 5%;
    transform: none;
    // position: static;
    margin:10vw 0 0 0;
  }
.imglb{top:sizem(180);left:sizem(-450);width: sizem(609);
}
.imgrb{top:sizem(150);right: sizem(-470);width: sizem(585);
}
.tree{
  .bg1{top:sizem(-50);right:sizem(-270);width:sizem(920);}
.tree1{right:sizem(20);top:sizem(90);width:sizem(65);}
.tree2{left:sizem(-10);top:sizem(60);width:sizem(91);}
.tree4{left:sizem(300);top:sizem(-70);width:sizem(90);}
}

  .btn {
    &.half {
      width: 280px;
    }
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(50% - 5em);
      margin-right: 0;
      + span {
        margin-left: 1.5em;
      }
    }
  }

  .info {
    width: 100%;
    justify-content: center;
    text-align: center;
    //margin-bottom: 40px;

    > * {
      margin-bottom: 12px;
      &.address {
        margin-bottom: 0;
      }
    }
  }

  .logo {
  margin: 15vw auto 10vw;
    left: auto;
    position: relative;
      width: $contact_logo_mobile_width;
      filter: drop-shadow(1vw 0.7vw 0.4vw #000c);
  }
  .btn,
  .address,
  .google-btn {
    width: 100%;
  }
 // .google-btn {  }
  .addressall{
    width: 100%;
 //   border-radius: 30px;
  }
  .address {
    // width: 280px;
    padding: .9em 1em;
    text-align: justify;
    //font-size: 14px;
    height: auto;
  }

  .google-btn {
    margin-top: 15px;
    left: 0;
  }
}

@media only screen and (max-width: 321px) {
  .address {
    font-size: 14px;
  }
}
</style>
